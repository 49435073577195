import { IAccount } from 'api/account.types'
import { ISearchResult } from 'api/common.types'
import { ActionType, createAction } from 'typesafe-actions'
import {
  ICheckDepositApiReq,
  IExistedAccountApiReq,
  IGetAdvisorAccountsByClientIdApiRes,
  IGetCheckDepositByTransactionNoApiRes,
  IInsertUpdateCheckDepositApiReq,
  IInsertUpdateCheckDepositApiRes,
  ISortAndFilter
} from '../api/types'

export const ACCOUNT_SEARCH_REQUEST = `@modules/@RCMCheckDeposit/ACCOUNT_SEARCH_REQUEST`
export const ACCOUNT_SEARCH_SUCCESS = `@modules/@RCMCheckDeposit/ACCOUNT_SEARCH_SUCCESS`
export const ACCOUNT_SEARCH_FAILURE = `@modules/@RCMCheckDeposit/ACCOUNT_SEARCH_FAILURE`
export const ACCOUNT_SEARCH_CLEAR = `@modules/@RCMCheckDeposit/ACCOUNT_SEARCH_CLEAR`

export const accountSearchActions = {
  request: createAction(ACCOUNT_SEARCH_REQUEST)<IExistedAccountApiReq>(),
  success: createAction(ACCOUNT_SEARCH_SUCCESS)<ISearchResult<IAccount>>(),
  failure: createAction(ACCOUNT_SEARCH_FAILURE)<Error>(),
  clear: createAction(ACCOUNT_SEARCH_CLEAR)()
}

export const CHECK_DEPOSIT_BY_TRANSACTIONNO = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_BY_TRANSACTIONNO`
export const CHECK_DEPOSIT_BY_TRANSACTIONNO_SUCCESS = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_BY_TRANSACTIONNO_SUCCESS`
export const CHECK_DEPOSIT_BY_TRANSACTIONNO_FAILURE = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_BY_TRANSACTIONNO_FAILURE`
export const CHECK_DEPOSIT_BY_TRANSACTIONNO_CLEAR = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_BY_TRANSACTIONNO_CLEAR`

export const fetchCheckDepositServiceByTransactionNoActions = {
  request: createAction(CHECK_DEPOSIT_BY_TRANSACTIONNO)<
    ICheckDepositApiReq | undefined
  >(),
  success: createAction(
    CHECK_DEPOSIT_BY_TRANSACTIONNO_SUCCESS
  )<IGetCheckDepositByTransactionNoApiRes>(),
  failure: createAction(CHECK_DEPOSIT_BY_TRANSACTIONNO_FAILURE)<Error>(),
  clear: createAction(CHECK_DEPOSIT_BY_TRANSACTIONNO_CLEAR)<undefined>()
}

export const INSERT_UPDATE_CHECK_DEPOSIT = `@module/@RCMCheckDeposit/INSERT_UPDATE_CHECK_DEPOSIT`
export const INSERT_UPDATE_CHECK_DEPOSIT_SUCCESS = `@module/@RCMCheckDeposit/INSERT_UPDATE_CHECK_DEPOSIT_SUCCESS`
export const INSERT_UPDATE_CHECK_DEPOSIT_FAILURE = `@module/@RCMCheckDeposit/INSERT_UPDATE_CHECK_DEPOSIT_FAILURE`
export const INSERT_UPDATE_CHECK_DEPOSIT_CLEAR = `@module/@RCMCheckDeposit/INSERT_UPDATE_CHECK_DEPOSIT_CLEAR`

export const insertUpdateCheckDepositActions = {
  request: createAction(
    INSERT_UPDATE_CHECK_DEPOSIT
  )<IInsertUpdateCheckDepositApiReq>(),
  success: createAction(
    INSERT_UPDATE_CHECK_DEPOSIT_SUCCESS
  )<IInsertUpdateCheckDepositApiRes>(),
  failure: createAction(INSERT_UPDATE_CHECK_DEPOSIT_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_CHECK_DEPOSIT_CLEAR)()
}

export const ADVISOR_ACCOUNT_BY_CLIENTID = `@modules/@RCMCheckDeposit/ADVISOR_ACCOUNT_BY_CLIENTID`
export const ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS = `@modules/@RCMCheckDeposit/ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS`
export const ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE = `@modules/@RCMCheckDeposit/ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE`
export const ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR = `@modules/@RCMCheckDeposit/ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR`

export const fetchAdvisorAccountsByClientIdActions = {
  request: createAction(ADVISOR_ACCOUNT_BY_CLIENTID)<string>(),
  success: createAction(
    ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS
  )<IGetAdvisorAccountsByClientIdApiRes>(),
  failure: createAction(ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE)<Error>(),
  clear: createAction(ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR)<undefined>()
}

export const CLIENT_SEARCH_REQUEST = `@modules/@RCMCheckDeposit/CLIENT_SEARCH_REQUEST`
export const CLIENT_SEARCH_SUCCESS = `@modules/@RCMCheckDeposit/CLIENT_SEARCH_SUCCESS`
export const CLIENT_SEARCH_FAILURE = `@modules/@RCMCheckDeposit/CLIENT_SEARCH_FAILURE`
export const CLIENT_SEARCH_CLEAR = `@modules/@RCMCheckDeposit/CLIENT_SEARCH_CLEAR`

export const clientSearchActions = {
  request: createAction(CLIENT_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(CLIENT_SEARCH_SUCCESS)<ISearchResult<any>>(),
  failure: createAction(CLIENT_SEARCH_FAILURE)<Error>(),
  clear: createAction(CLIENT_SEARCH_CLEAR)()
}

export const GET_TRANSACTION_NO = `@modules/@RCMCheckDeposit/GET_TRANSACTION_NO`
export const GET_TRANSACTION_NO_SUCCESS = `@modules/@RCMCheckDeposit/GET_TRANSACTION_NO_SUCCESS`
export const GET_TRANSACTION_NO_FAILURE = `@modules/@RCMCheckDeposit/GET_TRANSACTION_NO_FAILURE`
export const GET_TRANSACTION_NO_CLEAR = `@modules/@RCMCheckDeposit/GET_TRANSACTION_NO_CLEAR`

export const fetchTransactionNoAction = {
  request: createAction(GET_TRANSACTION_NO)(),
  success: createAction(GET_TRANSACTION_NO_SUCCESS)<string>(),
  failure: createAction(GET_TRANSACTION_NO_FAILURE)<Error>(),
  clear: createAction(GET_TRANSACTION_NO_CLEAR)<undefined>()
}

export const CHECK_DEPOSIT_SORT_AND_FILTER = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_SORT_AND_FILTER`
export const CHECK_DEPOSIT_SORT_AND_FILTER_SUCCESS = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_SORT_AND_FILTER_SUCCESS`
export const CHECK_DEPOSIT_SORT_AND_FILTER_FAILURE = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_SORT_AND_FILTER_FAILURE`
export const CHECK_DEPOSIT_SORT_AND_FILTER_CLEAR = `@modules/@RCMCheckDeposit/CHECK_DEPOSIT_SORT_AND_FILTER_CLEAR`

export const fetchCheckDepositSortAndFilterActions = {
  request: createAction(CHECK_DEPOSIT_SORT_AND_FILTER)<
    ISortAndFilter | undefined
  >(),
  success: createAction(CHECK_DEPOSIT_SORT_AND_FILTER_SUCCESS)<
    ISortAndFilter | undefined
  >(),
  failure: createAction(CHECK_DEPOSIT_SORT_AND_FILTER_FAILURE)<Error>(),
  clear: createAction(CHECK_DEPOSIT_SORT_AND_FILTER_CLEAR)<undefined>()
}

export type accountSearchActionType = ActionType<typeof accountSearchActions>
export type clientSearchActionType = ActionType<typeof clientSearchActions>
export type fetchAdvisorAccountsByClientIdActionsType = ActionType<
  typeof fetchAdvisorAccountsByClientIdActions
>
export type fetchCheckDepositServiceByTransactionNoActionsType = ActionType<
  typeof fetchCheckDepositServiceByTransactionNoActions
>
export type insertUpdateCheckDepositActionsType = ActionType<
  typeof insertUpdateCheckDepositActions
>
export type fetchTransactionNoActionType = ActionType<
  typeof fetchTransactionNoAction
>

export type RCMCDActionsTypes =
  | fetchCheckDepositServiceByTransactionNoActionsType
  | insertUpdateCheckDepositActionsType
  | accountSearchActionType
  | fetchAdvisorAccountsByClientIdActionsType
  | clientSearchActionType
  | fetchTransactionNoActionType
  | ActionType<typeof fetchCheckDepositSortAndFilterActions>
