import { ActionType, createAction } from 'typesafe-actions'
import {
  IFetchOcrDocsReq,
  IOcrAttachmentGetResponce,
  IOcrProcessedFileGetResponce,
  IUploadOCRRequest,
  IUploadOcrFiles
} from './types'

export const FETCH_OCR_UPLOAD = `@modules/@OCR/FETCH_OCR_UPLOAD`
export const FETCH_OCR_UPLOAD_SUCCESS = `@modules/@OCR/FETCH_OCR_UPLOAD_SUCCESS`
export const FETCH_OCR_UPLOAD_FAILURE = `@modules/@OCR/FETCH_OCR_UPLOAD_FAILURE`
export const FETCH_OCR_UPLOAD_CLEAR = `@modules/@OCR/FETCH_OCR_UPLOAD_CLEAR`

export const fetchOcrFileActions = {
  request: createAction(FETCH_OCR_UPLOAD)<IFetchOcrDocsReq>(),
  success: createAction(FETCH_OCR_UPLOAD_SUCCESS)<IOcrAttachmentGetResponce>(),
  failure: createAction(FETCH_OCR_UPLOAD_FAILURE)<Error>(),
  clear: createAction(FETCH_OCR_UPLOAD_CLEAR)<undefined>()
}

export const UPLOAD_OCR_FILE_REQUEST = `@modules/@OCR/UPLOAD_OCR_FILE_REQUEST`

export const uploadOcrActions = {
  uploadDoc: createAction(UPLOAD_OCR_FILE_REQUEST)<IUploadOCRRequest>()
}
export const UPLOAD_OCR_FILE_SUCCESS = `@modules/@OCR/UPLOAD_OCR_FILE_SUCCESS`
export const UPLOAD_OCR_FILE_FAILURE = `@modules/@OCR/UPLOAD_OCR_FILE_FAILURE`
export const UPLOAD_OCR_FILE_CLEAR = `@modules/@OCR/UPLOAD_OCR_FILE_CLEAR`

export const uploadOcrFileActions = {
  success: createAction(UPLOAD_OCR_FILE_SUCCESS)<IUploadOcrFiles>(),
  failure: createAction(UPLOAD_OCR_FILE_FAILURE)<Error>()
}

export const VIEW_OCR_FILE = `@modules/@OCR/VIEW_OCR_FILE`
export const VIEW_OCR_FILE_SUCCESS = `@modules/@OCR/VIEW_OCR_FILE_SUCCESS`
export const VIEW_OCR_FILE_FAILURE = `@modules/@OCR/VIEW_OCR_FILE_FAILURE`

export const viewOcrFileActions = {
  request: createAction(VIEW_OCR_FILE)<{
    fileUrlPath: string
    downloadFileName: string
  }>(),
  success: createAction(VIEW_OCR_FILE_SUCCESS)(),
  failure: createAction(VIEW_OCR_FILE_FAILURE)<Error>()
}

export const DELETE_OCR_FILE = `@modules/@OCR/DELETE_OCR_FILE`
export const DELETE_OCR_FILE_SUCCESS = `@modules/@OCR/DELETE_OCR_FILE_SUCCESS`
export const DELETE_OCR_FILE_FAILURE = `@modules/@OCR/DELETE_OCR_FILE_FAILURE`
export const DELETE_OCR_FILE_CLEAR = `@modules/@OCR/DELETE_OCR_FILE_CLEAR`

export const deleteOcrFileActions = {
  request: createAction(DELETE_OCR_FILE)<{
    fileUrlPath: string
    attachmentType: string
  }>(),
  success: createAction(DELETE_OCR_FILE_SUCCESS)(),
  failure: createAction(DELETE_OCR_FILE_FAILURE)<Error>(),
  clear: createAction(DELETE_OCR_FILE_CLEAR)<undefined>()
}

export const FETCH_OCR_PDF_PROCESSED = `@modules/@OCR/FETCH_OCR_PDF_PROCESSED`
export const FETCH_OCR_PDF_PROCESSED_SUCCESS = `@modules/@OCR/FETCH_OCR_PDF_PROCESSED_SUCCESS`
export const FETCH_OCR_PDF_PROCESSED_FAILURE = `@modules/@OCR/FETCH_OCR_PDF_PROCESSED_FAILURE`
export const FETCH_OCR_PDF_PROCESSED_CLEAR = `@modules/@OCR/FETCH_OCR_PDF_PROCESSED_CLEAR`

export const fetchOcrPDFProcessedActions = {
  request: createAction(FETCH_OCR_PDF_PROCESSED)(),
  success: createAction(
    FETCH_OCR_PDF_PROCESSED_SUCCESS
  )<IOcrProcessedFileGetResponce>(),
  failure: createAction(FETCH_OCR_PDF_PROCESSED_FAILURE)<Error>(),
  clear: createAction(FETCH_OCR_PDF_PROCESSED_CLEAR)<undefined>()
}

export type fetchOcrFileTypeActions = ActionType<typeof fetchOcrFileActions>
export type viewOcrFileActionsTypes = ActionType<typeof viewOcrFileActions>
export type deleteOcrFileActionsType = ActionType<typeof deleteOcrFileActions>
export type fetchOcrPDFProcessedActionsType = ActionType<
  typeof fetchOcrPDFProcessedActions
>

export type OcrActionTypes =
  | fetchOcrFileTypeActions
  | viewOcrFileActionsTypes
  | deleteOcrFileActionsType
  | fetchOcrPDFProcessedActionsType
