import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import {
  IAccountSearchState,
  IClientSearchState,
  IErrorSearchState,
  IGetAdvisorAccountsByClientIdApiRes,
  IGetCheckDepositByTransactionNoApiRes,
  ISortAndFilter
} from '../api/types'
import {
  accountSearchActions,
  clientSearchActions,
  fetchAdvisorAccountsByClientIdActions,
  fetchCheckDepositServiceByTransactionNoActions,
  fetchCheckDepositSortAndFilterActions,
  fetchTransactionNoAction,
  insertUpdateCheckDepositActions,
  RCMCDActionsTypes
} from './actions'

export interface IRCMCDState {
  errorSearchState?: IErrorSearchState
  accountSearchState?: IAccountSearchState
  clientSearchState?: IClientSearchState
  fetchCheckDepositServiceByTransactionNoState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IGetCheckDepositByTransactionNoApiRes
  }
  insertUpdateCheckDepositState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  fetchAdvisorAccountsByClientIdState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IGetAdvisorAccountsByClientIdApiRes
  }
  fetchTransactionNumberState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: string
  }
  sortAndFilter?: {
    data?: ISortAndFilter
  }
}
const initialState: IRCMCDState = {}
export const rcmcdReducer = createReducer<IRCMCDState, RCMCDActionsTypes>(
  initialState
)
  .handleAction(
    fetchCheckDepositServiceByTransactionNoActions.request,
    (state) => ({
      ...state,
      fetchCheckDepositServiceByTransactionNoState: {
        ...state.fetchCheckDepositServiceByTransactionNoState,
        inProgress: true,
        error: undefined,
        messageType: MessageBarType.info,
        msg: 'Get Check deposit by Transation Number'
      }
    })
  )
  .handleAction(
    fetchCheckDepositServiceByTransactionNoActions.success,
    (state, action) => ({
      ...state,
      fetchCheckDepositServiceByTransactionNoState: {
        inProgress: false,
        error: undefined,
        messageType: MessageBarType.success,
        msg: 'Successfully fetched Chech Deposit',
        data: action.payload
      }
    })
  )
  .handleAction(
    fetchCheckDepositServiceByTransactionNoActions.failure,
    (state, action) => ({
      ...state,
      fetchCheckDepositServiceByTransactionNoState: {
        ...state.fetchCheckDepositServiceByTransactionNoState,
        inProgress: false,
        error: action.payload,
        msg: action.payload.message,
        messageType: MessageBarType.error
      }
    })
  )
  .handleAction(
    fetchCheckDepositServiceByTransactionNoActions.clear,
    (state) => ({
      ...state,
      fetchCheckDepositServiceByTransactionNoState: undefined
    })
  )

  .handleAction(insertUpdateCheckDepositActions.request, (state) => ({
    ...state,
    insertUpdateCheckDepositState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateCheckDepositActions.success, (state, action) => ({
    ...state,
    insertUpdateCheckDepositState: {
      inProgress: false,
      error: undefined,
      msg: 'Successfully saved record.',
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(insertUpdateCheckDepositActions.failure, (state, action) => ({
    ...state,
    insertUpdateCheckDepositState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateCheckDepositActions.clear, (state) => ({
    ...state,
    insertUpdateCheckDepositState: undefined
  }))

  .handleAction(fetchAdvisorAccountsByClientIdActions.request, (state) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: {
      ...state.fetchAdvisorAccountsByClientIdState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Advisor Security by Cusip'
    }
  }))
  .handleAction(
    fetchAdvisorAccountsByClientIdActions.success,
    (state, action) => ({
      ...state,
      fetchAdvisorAccountsByClientIdState: {
        inProgress: false,
        error: undefined,
        messageType: MessageBarType.success,
        msg: 'Successfully fetched Advisor security by Cusip',
        data: action.payload
      }
    })
  )
  .handleAction(
    fetchAdvisorAccountsByClientIdActions.failure,
    (state, action) => ({
      ...state,
      fetchAdvisorAccountsByClientIdState: {
        ...state.fetchAdvisorAccountsByClientIdState,
        inProgress: false,
        error: action.payload,
        msg: action.payload.message,
        messageType: MessageBarType.error
      }
    })
  )
  .handleAction(fetchAdvisorAccountsByClientIdActions.clear, (state) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: undefined
  }))
  .handleAction(accountSearchActions.request, (state) => ({
    ...state,
    accountSearchState: {
      ...state.accountSearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.success, (state, action) => ({
    ...state,
    accountSearchState: {
      inProgress: false,
      accountSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.failure, (state, action) => ({
    ...state,
    accountSearchState: {
      inProgress: false,
      advisorSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(accountSearchActions.clear, (state) => ({
    ...state,
    accountSearchState: undefined
  }))

  .handleAction(clientSearchActions.request, (state) => ({
    ...state,
    clientSearchState: {
      ...state.clientSearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.success, (state, action) => ({
    ...state,
    clientSearchState: {
      inProgress: false,
      clientSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.failure, (state, action) => ({
    ...state,
    clientSearchState: {
      inProgress: false,
      clientSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(clientSearchActions.clear, (state) => ({
    ...state,
    clientSearchState: undefined
  }))
  .handleAction(fetchTransactionNoAction.request, (state) => ({
    ...state,
    fetchTransactionNumberState: {
      ...state.fetchTransactionNumberState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Generating the Transaction Number'
    }
  }))
  .handleAction(fetchTransactionNoAction.success, (state, action) => ({
    ...state,
    fetchTransactionNumberState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully generated Transaction Number',
      data: action.payload
    }
  }))
  .handleAction(fetchTransactionNoAction.failure, (state, action) => ({
    ...state,
    fetchTransactionNumberState: {
      ...state.fetchTransactionNumberState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchTransactionNoAction.clear, (state) => ({
    ...state,
    fetchTransactionNumberState: undefined
  }))
  .handleAction(fetchCheckDepositSortAndFilterActions.request, (state) => ({
    ...state,
    sortAndFilter: {
      ...state.sortAndFilter
    }
  }))
  .handleAction(
    fetchCheckDepositSortAndFilterActions.success,
    (state, action) => ({
      ...state,
      sortAndFilter: {
        data: action.payload
      }
    })
  )
  .handleAction(fetchCheckDepositSortAndFilterActions.failure, (state) => ({
    ...state,
    sortAndFilter: {
      ...state.sortAndFilter
    }
  }))
  .handleAction(fetchCheckDepositSortAndFilterActions.clear, (state) => ({
    ...state,
    sortAndFilter: undefined
  }))
